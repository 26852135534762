<template>
  <div class="productDetails">
    <div class="banner">
      <img :src="banner" alt="" />
    </div>
    <div class="content">
      <div class="title">产品细分</div>
      <ul class="subdivide">
        <li v-for="item in productList" :key="item.title">
          <div class="subdivide_left">
            <div class="subdivide_title">{{ item.title }}</div>
            <div class="subdivide_text" v-html="item.describes"></div>
          </div>
          <div class="subdivide_right" v-if="item.image">
            <img :src="item.image.split(',')[0]" alt="" />
            <img :src="item.image.split(',')[1]" alt="" />
          </div>
        </li>
      </ul>
      <div class="advantage_title">
        <span>产品优势</span>
        <span>16年深厚技术沉淀，专业领先、产品优异</span>
      </div>
      <ul class="advantage_list">
        <li v-for="item in advantagesList" :key="item.advantagesId">
          <div>
            <img :src="item.image" alt="" />
          </div>
          <span>{{ item.advantagesName }}</span>
          <span>{{ item.introduce }}</span>
        </li>
      </ul>
      <div class="representation_title">表现形式</div>
      <ul class="representation_list">
        <li>
          <img src="@/assets/productDetails/PC.png" alt="" />
          <span>实现网络环境下教学互动</span>
        </li>
        <li>
          <img src="@/assets/productDetails/Android.png" alt="" />
          <span>实现随时随地学习互动</span>
        </li>
        <li>
          <img src="@/assets/productDetails/SelectiveFocus.png" alt="" />
          <span>模拟教学用具与仿真配合</span>
        </li>
        <li>
          <img src="@/assets/productDetails/AR.png" alt="" />
          <span>将虚拟与现实相结合</span>
        </li>
        <li>
          <img src="@/assets/productDetails/VR.png" alt="" />
          <span>沉浸式体验震撼的视觉效果</span>
        </li>
        <li>
          <img src="@/assets/productDetails/3D.png" alt="" />
          <span>把教学方式进行立体化呈现</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { productInfo } from "@/api/web";
export default {
  name: "",
  data() {
    return {
      productList: [],
      banner: null,
      advantagesList: [],
    };
  },
  created() {
    this.getProductInfo();
  },
  methods: {
    async getProductInfo() {
      const { data } = await productInfo({
        productId: this.$route.params.productId,
      });
      this.productList = data.details;
      this.banner = data.totalImage;
      this.advantagesList = data.advantages;
    },
  },
};
</script>

<style scoped lang="scss">
.productDetails {
  .banner {
    img {
      margin-top: -1px;
    }
  }
  .content {
    width: 1460px;
    margin: 0 auto;
    .title {
      width: 144px;
      margin: 0 auto;
      margin-top: 47px;
      margin-bottom: 76px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      color: #181818;
    }
    .subdivide {
      width: 100%;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 12px;
        padding: 17px 0;
        padding-left: 65px;
        border: 1px solid #d1d1d1;
        box-sizing: border-box;
        .subdivide_left {
          flex: 1;
          margin-right: 55px;
          .subdivide_title {
            font-size: 32px;
          }
          .subdivide_text {
            margin-top: 15px;
            font-size: 21px;
            line-height: 36px;
          }
        }
        .subdivide_right {
          width: 600px;

          margin-right: 56px;
          img {
            margin-right: 20px;
            width: 290px;
            height: 226px;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
    .advantage_title {
      margin-top: 44px;
      span {
        display: block;
        margin: 0 auto;
        font-family: Microsoft YaHei;
        &:nth-of-type(1) {
          width: 144px;
          font-size: 36px;
          color: #181818;
        }
        &:nth-of-type(2) {
          margin-top: 5px;
          width: 382px;
          font-size: 21px;
          color: #27499d;
        }
      }
    }
    .advantage_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      li {
        // height: 300px;
        margin-bottom: 50px;
        width: 40%;
        border: none;
        text-align: center;
        // div {
        //   width: 104px;
        //   height: 126px;
        //   margin: 0 auto;
        // }
        span {
          display: block;
          &:nth-of-type(1) {
            margin: 20px 0;
            font-size: 21px;
            font-weight: bold;
            color: #181818;
          }
          &:nth-of-type(2) {
            font-size: 18px;
            color: #323232;
            line-height: 25px;
            text-align: left;
          }
        }
      }
    }
    .representation_title {
      margin: 0 auto;
      width: 147px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      color: #181818;
    }
    .representation_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 53px;
      li {
        display: flex;
        flex-direction: column;
        margin-right: 246px;
        margin-bottom: 108px;
        text-align: center;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        img {
          width: 283px;
          height: 248px;
        }
        span {
          margin-top: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #323232;
        }
      }
    }
  }
}
</style>
